/*global jQuery, google */
(function($) {

  'use strict';

  $(function() {

    $(document).ready(function () {
      if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
        //$('#banner img').each(function() {
        //  var $src = $(this).attr('src');
        //  $(this).parent().css({
        //    'background-image' : 'url(' + $src + ')',
        //  });
        //  $(this).hide();
        //});

        //var $item = $('#banner'); 
        //var $wHeight = $(window).height();
        //$item.height($wHeight/1.5);
//
//        //$(window).on('resize', function (){
//        //  $wHeight = $(window).height();
//        //  $item.height($wHeight/1.5);
        //});
      } 
    });

    $('.owl-carousel.bannerhome').owlCarousel({
      animateOut: 'fadeOut',
      mergeFit: true,
      responsiveRefreshRate: 200,
      margin : 0,
      loop: true,
      autoplay: true,
      autoplayTimeout: 3000,
      autoplayHoverPause: true,
      autoplaySpeed: 1000,
      items: 1,
      nav: true,
      dots: false,
      navText:['<div class="left carousel-control hidden-xs"><span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span></div>',
              '<div class="right carousel-control hidden-xs"><span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span></div>'],
      
    });

    
  });

}(jQuery));